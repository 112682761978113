const getDate = (date, separated = '-', notFullYear = false) => {
    if (date instanceof Date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()

        if (notFullYear) year = year.toString().slice(2, 4)
        if (month < 10) month = `0${month}`
        if (day < 10) day = `0${day}`

        return `${year}${separated}${month}${separated}${day}`
    } else return '';
}

const getDateTime = (dateTime) => {
    if (dateTime instanceof Date) {
        let month = dateTime.getMonth() + 1;
        let day = dateTime.getDate()
        let hour = dateTime.getHours()
        let minutes = dateTime.getMinutes()

        if (month < 10) month = `0${month}`
        if (day < 10) day = `0${day}`
        if (hour < 10) hour = `0${hour}`
        if (minutes < 10) minutes = `0${minutes}`

        return `${dateTime.getFullYear()}-${month}-${day} ${hour}:${minutes}`
    } else return '';
}

const getDateTimeSec = (dateTime) => {
    if (dateTime instanceof Date) {
        let month = dateTime.getMonth() + 1;
        let day = dateTime.getDate()
        let hour = dateTime.getHours()
        let minutes = dateTime.getMinutes()
        let second = dateTime.getSeconds()

        if (month < 10) month = `0${month}`
        if (day < 10) day = `0${day}`
        if (hour < 10) hour = `0${hour}`
        if (minutes < 10) minutes = `0${minutes}`
        if (second < 10) second = `0${second}`

        return `${dateTime.getFullYear()}-${month}-${day} ${hour}:${minutes}:${second}`
    } else return '';
}
const getDateTimeSecs = (dateTime)=> {
    // KST 시간대로 변환
    const kstOffset = 9 * 60; // KST는 UTC+9
    const kstDate = new Date(dateTime.getTime() + kstOffset * 60 * 1000);
  
    // 년, 월, 일, 시, 분, 초, 밀리초 가져오기
    const year = kstDate.getUTCFullYear();
    const month = String(kstDate.getUTCMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(kstDate.getUTCDate()).padStart(2, '0');
    const hours = String(kstDate.getUTCHours()).padStart(2, '0');
    const minutes = String(kstDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(kstDate.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(kstDate.getUTCMilliseconds()).padStart(3, '0');
  
    // 형식에 맞게 반환 (예: 2024-06-03 17:26:36.123)
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const toTimestamp = (dateTime) => {
    return dateTime.getTime();
  }
module.exports = {
    getDate,
    getDateTime,
    getDateTimeSec,
    getDateTimeSecs,
    toTimestamp,
}
