import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";
//import axios from '@/axios';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'login',
            component: () => import('../views/Login.vue'),
            meta: {
                title: '로그인'
            }
        },
        {
            path: '/pages/error-404',
            name: 'pages-error-404',
            component: () => import('../views/Error404'),
            meta: {
                title: '404',
            }
        },
        {
            // =============================================================================
            // MASTER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/master/MasterView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/home',
                    name: 'home',
                    component: () => import('../views/Home.vue'),
                    meta: {
                        title: '홈',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/map',
                    name: 'map',
                    component: () => import('../views/map/Map.vue'),
                    props: route => ({ fromHome: route.query.fromHome === 'true' }),
                    meta: {
                        title: '지도',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/drone',
                    name: 'droneList',
                    component: () => import('../views/drone/DroneList.vue'),
                    meta: {
                        title: '드론 로그 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/drone-detail',
                    name: 'DroneDetail',
                    component: () => import('../views/drone/DroneDetail.vue'),
                    props: route =>({
                        startdate: route.params.startDate,
                        enddate: route.params.endDate,
                        deviceId: route.params.deviceId,  
                        isFlying: route.params.isFlying,                      
                    }),
                    meta: {
                        title: '드론 로그 상세 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/drone/:id',
                    name: 'droneInfo',
                    component: () => import('../views/drone/DroneInfo.vue'),
                    meta: {
                        title: '드론 로그 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/dronedevice',
                    name: 'droneDeviceList',
                    component: () => import('../views/drone/DroneDeviceList.vue'),
                    meta: {
                        title: '드론 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/dronedevice/:id',
                    name: 'droneDeviceInfo',
                    component: () => import('../views/drone/DroneDeviceInfo.vue'),
                    meta: {
                        title: '드론 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/users',
                    name: 'userList',
                    component: () => import('../views/users/UserList.vue'),
                    meta: {
                        title: '회원 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/user/:id',
                    name: 'userInfo',
                    component: () => import('../views/users/UserInfo.vue'),
                    meta: {
                        title: '회원 정보 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/non-users',
                    name: 'nonUserList',
                    component: () => import('../views/users/NonUserList.vue'),
                    meta: {
                        title: '미승인 회원 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/non-user/:id',
                    name: 'nonUserInfo',
                    component: () => import('../views/users/NonUserInfo.vue'),
                    meta: {
                        title: '미승인 회원 정보 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/sessionTimeout',
                    name: 'sessionTimeout',
                    component: () => import('../views/settings/session.vue'),
                    meta: {
                        title: '세션타임아웃 설정',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/password',
                    name: 'password',
                    component: () => import('../views/settings/ChangePassword.vue'),
                    meta: {
                        title: '패스워드 변경',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/version',
                    name: 'version',
                    component: () => import('../views/settings/Version.vue'),
                    meta: {
                        title: '버전',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/certificatemodify',
                    name: 'certificatemodify',
                    component: () => import('../views/certificate/Modify.vue'),
                    meta: {
                        title: '증명서 작성',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/certificateissue',
                    name: 'certificateissue',
                    component: () => import('../views/certificate/Issue.vue'),
                    meta: {
                        title: '회원 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },                
                {
                    path: '/certificatelist',
                    name: 'certificatelist',
                    component: () => import('../views/certificate/List.vue'),
                    meta: {
                        title: '증명서 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/attendance',
                    name: 'attendance',
                    component: () => import('../views/users/Attendance.vue'),
                    meta: {
                        title: '출석 관리',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/logbookmodify',
                    name: 'logbookmodify',
                    component: () => import('../views/logbook/LogRecord.vue'),
                    meta: {
                        title: '기체비행기록부 작성',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/logbookissue',
                    name: 'logbookissue',
                    component: () => import('../views/logbook/LogList.vue'),
                    meta: {
                        title: '기체비행기록부 발급',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/logbooklist',
                    name: 'logbooklist',
                    component: () => import('../views/logbook/LogView.vue'),
                    meta: {
                        title: '기체비행기록부 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
            ],
        },
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ]
})

const makeTitle = (title) =>
    title ? `${title} | DRONE` : "DRONE";

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = makeTitle(to.meta.title);
    });
});


// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
    
    let token = null;
    if (localStorage.token !== undefined) {
        token = JSON.parse(localStorage.token);
    }
    // await store.dispatch('setAuth', token)
    //     .then(() => {
    //         if (token) {
    //             console.log(token)
    //         } else {
    //             console.log('없다.')
    //         }
    //     })

    if (token) {
        try {
            // 토큰 유효성 확인 요청을 보냅니다.
            //await axios.get('/auth/validate-token');
            await store.dispatch('setAuth', token);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert('세션이 만료되었습니다. 다시 로그인 해주세요.');
                localStorage.removeItem('token');
                return router.replace({ path: '/' });
            }
        }
    }

    if (to.meta.authRequired) {
        if (!token) {
            await router.replace({path: '/'}).catch(() => true)
        }
        
        //데이터가 없는 상태로 증명서 작성 페이지 이동 방지
        if (to.path ==='/certificatemodify' && !to.params.source) {
            next(false);
        } else {
            next();
        }
        
        //데이터가 없는 상태로 기록부 작성 페이지 이동 방지
        if (to.path ==='/logbookmodify' && !to.params.source) {
            next(false);
        } else {
            next();
        }

    } else {
        if (token) {
            if (to.path !== '/home' && to.path !== '/pages/error-404')
                await router.replace({path: '/home'}).catch(() => true)
        }
        
        next();
    }
    //next()
});

export default router
