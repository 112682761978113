import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/axios';
import { getDateTimeSecs } from "@/lib/prettyDate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    logs: [],
    selectedDate: '',
    deviceId:'',
    devices: [],
    currentPage: 1,
    location: { lat:null, lng: null},
    weather:null,
    sessionTimeout: localStorage.getItem('sessionTimeout') || '86400s'
  },
  mutations: {
    setLocation(state, payload) {
      state.location.lat = payload.lat;
      state.location.lng = payload.lng;
      localStorage.setItem('userLocation', JSON.stringify(state.location));
    },
    setSessionTimeout(state, timeout) {    
      state.sessionTimeout = timeout;
      localStorage.setItem('sessionTimeout', timeout); // 로컬 스토리지에 저장
      axios.defaults.headers.common['session-timeout'] = timeout;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLogs(state, logs) {
      state.logs = logs;
    },
    setSelectedDate(state, date) {
      state.selectedDate = date;
    },
    setdeviceId(state, deviceId){
      state.deviceId = deviceId;
    },
    setDevices(state, devices){
      state.devices = devices;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    initializeLocation(state) {
      const savedLocation = localStorage.getItem('userLocation');
      if (savedLocation) {
        state.location = JSON.parse(savedLocation);
      }
    },
    setWeather(state, data){
      state.weather = data;
    },
        
  },
  actions: {
    fetchLocation({ commit }) {
      commit('initializeLocation');
    },
    setWeatherData({commit}, weather)
    {
      commit('setWeather', weather);
    },    
    async setAuth({ commit }, data) {
      commit('setToken', data);
    },
    updateSessionTimeout({ commit }, timeout) {
      commit('setSessionTimeout', timeout);
    },
    fetchLogs({ commit, state }) {
      const config = {
        method: 'get',
        url: '/locations/DroneLog',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token.access_token}`
        },
        params: { date: state.selectedDate , deviceId: state.deviceId }
      };
      
      axios(config)
        .then(response => {
          const data = response.data;
          const logs = data.flatMap(item => item.data);
          logs.forEach(item => {
            item.startDate = getDateTimeSecs(new Date(item.startDate));
            item.endDate = getDateTimeSecs(new Date(item.endDate));
            // 1970년인 경우 isFlying 속성 추가
            item.isFlying = new Date(item.endDate).getFullYear() === 1970;
            
          });

          // logs를 시작 날짜(startDate) 기준으로 내림차순 정렬
          logs.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
          
          // 중복 제거된 devices 계산
          const devices = logs.reduce((acc, log) => {
            if (!acc.find(item => item.id === log.deviceId)) {
              acc.push({ id: log.deviceId });
            }
            return acc;
          }, []);
          //console.log('Fetched devices:', devices);
          //console.log('state.deviceId: ',state.deviceId);
          //console.log('Fetched logs:', logs);
          commit('setDevices', devices);          
          commit('setLogs', logs);
        })
        .catch(error => {
          console.error(error);
        });

        
    },
    
  },
  getters: {
    token: state => state.token,
    logs: state => state.logs,
    selectedDate: state => state.selectedDate,
    deviceId: state => state.deviceId,
    devices: state => state.devices,
    currentPage: state => state.currentPage,
    currentLocation: state => state.location
  }
});
